
<template>
  <Navbar />
  <v-container class="h-screen">
    <h2>Contact</h2>
  </v-container>
  <Footer />
</template>
  
<script>

import { defineComponent } from 'vue';

import Navbar from '../components/Navbar/Navbar.vue';
import Footer from '@/components/Footer/Footer.vue';

export default defineComponent({
  name: 'ContactView',

  components: {
    Navbar, Footer
  },
});

</script>