<template>
  <Navbar />
  <UserCourseCards />
  <Footer />
</template>
  
<script>

import { defineComponent } from 'vue';

import Navbar from '../components/Navbar/Navbar.vue';
import UserCourseCards from '../components/Course/UserCourseCards.vue';
import Footer from '@/components/Footer/Footer.vue';

export default defineComponent({
  name: 'ContactView',

  components: {
    Navbar, UserCourseCards, Footer
  },
});

</script>
