import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

loadFonts()

// for firebase setting
import { initializeApp } from "firebase/app";
import { firebaseConfig } from './firebase'
import { getFirestore} from 'firebase/firestore';
const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app')

export default db;