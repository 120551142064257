<template>
    <v-dialog v-model="isShowDialog" persistent width="700px">
        <template v-slot:activator="{ props }">
            <a class="text-decoration-underline bg-transparent" v-bind="props">Privacy Notice</a>
        </template>
        <v-card>
            <v-card-title class="text-h4 text-center mt-2">
                Privacy Notice
            </v-card-title>
            <v-card-text>
                <small>Last updated November 20, 2023</small>             
                <br />
                <br />
                <p>The privacy notice for wanderershk, describes how and why we might collect, store, use and process your
                    information when you use our services. Reading this privacy notice will help you understand your privacy
                    rights and choices. If you do not agree with our policies and
                    practices, please do not use our Services.
                </p>

                <br />
                <h4>1. WHAT INFORMATION DO WE COLLECT?</h4>
                <br />
                <p>We collect personal information that you voluntarily provide to us when you register on the Services,
                    when you participate in activities on the Services,
                    or otherwise when you contact us. We do not process sensitive information. All personal information that
                    you provide us must be true, complete and accurate,
                    and you must notify us of any changes to such personal information.
                </p>

                <br />
                <h4>2. HOW DO WE PROCESS YOUR INFORMATION?</h4>
                <br />
                <p>We process your information to provide, improve, and administer our Services, and communicate with you,
                    for security and fraud prevention. We may also process your information
                    for other purposes with your consent. We may process your information so you can create and log in to
                    your account, as well as keep your account in working order.
                    In addition, we may process information about how you use our Services to better understand how they are
                    being used so we can improve them.
                </p>

                <br />
                <h4>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h4>
                <br />
                <p>In fact, we will not share your information with any third parties directly with your agreement. However,
                    when you are using our service, we assume that you agree to share the information that you have
                    submitted to us.
                    After that, we will process your information and share it with the specified user.</p>

                <br />
                <h4>4. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</h4>
                <br />
                <p>The Services may link to third-party websites, online services, or mobile applications and/or contain
                    advertisements from third parties that are not affiliated with us and which may link to other websites,
                    services, or applications.
                    Accordingly, we do not make any guarantee regarding any such third parties, and we will not be liable
                    for any loss or damage caused by the use of such third-party websites, services, or applications.
                </p>
                <br />
                <p>
                    The inclusion of a link to a third-party website, service, or application does not imply an endorsement
                    by us. We cannot guarantee the safety and privacy of the data you provide to any third parties. Any data
                    collected by third parties is not covered by this privacy notice.
                    We are not responsible for the content, privacy, security practises, and policies of any third parties,
                    including other websites, services, or applications that may be linked to or from the Services. You
                    should review the policies of such third parties and contact them directly to respond to your questions.
                </p>

                <br />
                <h4>5. HOW DO WE KEEP YOUR INFORMATION SAFE</h4>
                <br />
                <p>We aim to protect your personal information through a system of organizational and technical security
                    measures. We have implemented appropriate and reasonable technical and organizational security measures
                    designed to protect the security of any personal information we process. However, despite our safeguards
                    and efforts to secure your information, no electronic transmission over the Internet or information
                    storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
                    cybercriminals, or other unauthorized third parties will not able to defeat our security and improperly
                    collect, access, steal, or modify your information. Although we will do our best to protect your
                    personal information, the transmission of personal information to and from our services is at your own
                    risk. You should only access the services in a secure environment.
                </p>

                <br />
                <h4>6. WHAT ARE YOUR PRIVACY RIGHTS</h4>
                <br />
                <p>If you would at any time like to review or change the information in your account or terminate your
                    account, you can contact us using the contact information
                    provided. Upon your request to terminate your account, we will deactivate or delete your account and
                    information from our active database. However, we may
                    retain some of our files to prevent fraud, troubleshoot problems, assist with any investigations,
                enforce our legal terms, and comply with applicable legal requirements.
            </p>

        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="bg-red" variant="text" @click="isShowDialog = false">
                Close
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog></template>

<script setup>
import { ref } from "vue";

const isShowDialog = ref(false);

</script>

<style scoped>a:hover {
    cursor: pointer;
}</style> 