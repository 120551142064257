<template>
    <v-card max-height="400" min-height="300" max-width="500" class="mx-auto fill-height d-flex flex-column justify-space-between">
        <v-card-item class="flex-grow-1 d-flex flex-column align-self-start">
            <v-card-title class="text-h5">{{ course.title }}</v-card-title>
            <v-card-subtitle class="mb-2">{{ course.subtitle }}</v-card-subtitle>
            <div class="mb-3">
                <p class="text-h6">Description</p>
                <p>{{ course.desc.length > 500 ? course.desc.slice(0, 500) + "..." : course.desc }}</p>
            </div>
            <div class="mb-3">
                <p class="text-h6">Desired Reward</p>
                <p>{{ course.reward }}</p>
            </div>
        </v-card-item>

        <v-card-actions class="justify-space-between">
            <DetailCard :course="course" />
            <ApplyCourseForm :course="course" />
        </v-card-actions>
    </v-card>
</template>

<script setup>
import { ref } from 'vue';
import ApplyCourseForm from './ApplyCourseForm.vue';
import DetailCard from './DetailCard.vue';

const props = defineProps(['course'])
const course = ref(props.course);

</script>