<template>
    <v-dialog v-model="isShowDialog" persistent width="1024">
        <template v-slot:activator="{ props }">
            <v-btn class="bg-info" v-bind="props" @click="isShowDialog=true" >Edit</v-btn>
        </template>
        <v-card>
            <v-card-title class="text-h4 text-center mt-3">
                Edit Service Form
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="6" md="8">
                            <v-text-field v-model="title" label="Service Title" variant="outlined" required></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="subtitle" label="Service Subtitle" variant="outlined"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea v-model="desc" label="Service Description" variant="outlined" required></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-select v-model="category" :items="['Academic', 'Language', 'Hobby', 'Sports', 'Work Skills']" variant="outlined"
                                label="Category" required></v-select>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field v-model="reward" label="Desired Reward" variant="outlined" multiple></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field v-model="location" label="Location" variant="outlined"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea v-model="remark" label="Remarks" variant="outlined"></v-textarea>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="d-flex justify-space-between pa-10">
                <v-btn class="bg-red" @click="isShowDialog = false">
                    Close
                </v-btn>
                <v-btn class="bg-info px-5 text-center" @click="updateCourseInfo">
                    Update
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog transition="dialog-top-transition" width="auto" v-model="isUpdated">
        <template v-slot:default="{ isActive }">
            <v-card>
                <v-toolbar color="success" title="Update Service Success"></v-toolbar>
                <v-card-text>
                    <div class="text-h5 pa-10">Successfully update the service</div>
                </v-card-text>
                <v-card-actions class="justify-space-between">
                    <v-btn class="bg-info" @click="isActive.value = false">Close</v-btn>
                    <v-btn class="bg-info" @click="isActive.value = false" href="/service">See the updated service</v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>

<script setup>
import { doc, updateDoc } from 'firebase/firestore';
import { onMounted, ref } from 'vue';
import db from '../../main';

const props = defineProps(['course'])
const course = ref(props.course);

const isShowDialog = ref(false);
const isUpdated = ref(false);

const title = ref('');
const subtitle = ref('');
const desc = ref(``);
const reward = ref('')
const category = ref('');
const location = ref('');
const remark = ref('');

const renderCourseInfo = () => {
    title.value = course.value.title;
    subtitle.value = course.value.subtitle;
    desc.value = course.value.desc;
    reward.value = course.value.reward;
    category.value = course.value.category;
    location.value = course.value.location;
    remark.value = course.value.remark;
}

const updateCourseInfo = async () => {
    const updatedDate = new Date();
    const targetDoc = doc(db, "services", course.value.id);

    try {
        const updateRef = await updateDoc(targetDoc, {
            title: title.value,
            subtitle: subtitle.value,
            desc: desc.value,
            category: category.value,
            reward: reward.value,
            location: location.value,
            remark: remark.value,
            updatedDate: updatedDate,
        });

        isShowDialog.value = false;
        isUpdated.value = true;
    } catch (err) {
        console.log(err);
    }
}

onMounted(() => {
    renderCourseInfo();
});

</script>