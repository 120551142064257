<template>
    <v-container class="mt-10 pa-5 h-screen">
        <v-card class="pa-10 mx-auto" max-width="700px">
            <v-form>
                <h1 class="mb-10 text-center">Register Form</h1>
                <v-text-field type="email" :rules="emailRules" class="mb-5" v-model="userEmail"
                    placeholder="2xxxxxxx@life.hkbu.edu.hk" label="Email" variant="outlined" required></v-text-field>
                <v-text-field type="password" :rules="passwordRules" class="mb-5" v-model="userPassword" label="Password"
                    variant="outlined" required></v-text-field>
                <v-text-field type="password" :rules="confirmPasswordRules" class="mb-3" v-model="userConfirmPassword"
                    label="Confirm password" variant="outlined" required></v-text-field>
                <v-checkbox v-model="isAgreeTerms" :rules="termsRules" class="mb-5" label="Agree Terms" required>
                    <template v-slot:label>
                        <div>
                            Agree
                            <Terms />
                        </div>
                    </template>
                </v-checkbox>
                <v-btn @click="register" :loading="isLoading" block class="mb-2 pa-6 bg-light-blue-darken-2"
                    text="Register"></v-btn>

                <!-- display the message is success or not -->
                <v-dialog transition="dialog-top-transition" width="auto" v-model="isShowDialog">
                    <template v-slot:default="{ isActive }">
                        <v-card>
                            <v-toolbar color="success" title="Register Success"></v-toolbar>
                            <v-card-text>
                                <div class="text-h5 pa-10">{{ responseMessage }}</div>
                            </v-card-text>
                            <v-card-actions class="justify-space-between">
                                <v-btn class="bg-info" @click="isActive.value = false">Close</v-btn>
                                <v-btn class="bg-info" @click="isActive.value = false" href="/login">
                                    Login
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>

                <v-dialog transition="dialog-top-transition" width="auto" v-model="isShowError">
                    <template v-slot:default="{ isActive }">
                        <v-card>
                            <v-toolbar class="bg-red" title="Register Failed"></v-toolbar>
                            <v-card-text>
                                <div class="text-h5 pa-10">{{ errorMessage }}</div>
                            </v-card-text>
                            <v-card-actions class="">
                                <v-spacer></v-spacer>
                                <v-btn class="bg-red" @click="isActive.value = false">Close</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>

            </v-form>
        </v-card>
    </v-container>
</template>


<script setup>
import { ref } from 'vue';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, signOut } from 'firebase/auth';
import { useRouter } from 'vue-router';

import db from '../../main';
import { collection, addDoc } from "firebase/firestore";

import Terms from '../Footer/Terms.vue';

const router = useRouter();
const userEmail = ref("");
const userPassword = ref("");
const userConfirmPassword = ref("");
const isAgreeTerms = ref(false);

const isHKBUEmail = ref(false);

const isShowDialog = ref(false);
const isLoading = ref(false);
const responseMessage = ref('');

const isShowError = ref(false);
const errorMessage = ref('');

const register = () => {
    // check the user input is valid or not

    if (userEmail.value == "" || userEmail.value == null || !isHKBUEmail.value) {
        errorMessage.value = 'Please input HKBU email.';
        isShowError.value = true;
        return;
    }

    if (userPassword.value == null || userPassword.value == '') {
        errorMessage.value = 'Please input password.';
        isShowError.value = true;
        return;
    }

    if (userPassword.value.length < 5) {
        errorMessage.value = 'Please input a password with at least 6 characters.';
        isShowError.value = true;
        return;
    }

    if (userPassword.value !== userConfirmPassword.value) {
        errorMessage.value = 'The password must be same as the confirm password.';
        isShowError.value = true;
        return;
    }

    if (isAgreeTerms.value != true) {
        errorMessage.value = 'Please agree the terms.';
        isShowError.value = true;
        return;
    }

    try {
        createUserWithEmailAndPassword(getAuth(), userEmail.value, userPassword.value)
            .then((data) => {

                // 2023/11/19 send email verification
                sendEmailVerification(getAuth().currentUser)
                    .then(() => {
                        // 2023/11/19 add the message box to ask the user to verify the email
                        responseMessage.value = 'Your registration is success. Please verify your account through email.'
                        isShowDialog.value = true;
                    }).catch((err) => {
                        errorMessage.value = 'Unexpected error occurs. Please try to register later.';
                        isShowError.value = true;
                    });

                docRef = addDoc(collection(db, "users"), {
                    email: userEmail.value,
                    userId: getAuth().currentUser.uid,
                    createdDate: new Date(),
                    updatedDate: new Date(),
                }).then((res) => console.log(res)).catch((err) => console.log(err));

                signOut(getAuth()).then(() => {});
            })
            .catch((err) => {
                if (err.code == 'auth/email-already-in-use') {
                    errorMessage.value = 'You have registered for this email. Please try to login.';
                    isShowError.value = true;
                }
            });

    } catch (err) {
        errorMessage.value = 'Unexpected error occurs. Please try to register later.';
        isShowError.value = true;
    }
};

const emailRules = [
    value => {
        const emailRegex = /\d{8}@life.hkbu.edu.hk/;

        isHKBUEmail.value = emailRegex.test(value);

        if (isHKBUEmail.value) {
            return true
        }

        return 'The email must be the HKBU email'
    },
];

const passwordRules = [
    value => {
        if (value.length >= 6) {
            return true
        }

        return 'The password should be at least 6 characters'
    },
];

const confirmPasswordRules = [
    value => {
        if (value == userPassword.value) {
            return true;
        }
        return 'The password and the confirmation password are not the same'
    },
]

const termsRules = [
    value => {
        if (value != true) {
            return "You must agree the terms"
        }
    }
];

</script>