<template>
  <Navbar />
  <LoginForm />
  <Footer />
</template>

<script>

import { defineComponent } from 'vue';

import Navbar from '../components/Navbar/Navbar.vue';
import LoginForm from '@/components/Login/LoginForm.vue';
import Footer from '@/components/Footer/Footer.vue';

export default defineComponent({
  name: 'LoginView',

  components: {
    Navbar, LoginForm, Footer
  },
});

</script>