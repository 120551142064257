<template>
  <Navbar />
  <main class="mb-10">
    <v-container class="about d-flex align-center justify-center flex-column">
      <v-card class="mt-10" max-width="900">
        <v-card-title>
          Project Background
        </v-card-title>
        <v-card-text class="text-body-1">
          We are HKBU students and notice that our schoolmates have different financial goals in their daily lives.
          Some students reduce their meals in order to pay their daily expenses. Some students need more money to sustain
          their interests. Some students want to buy new trends and technological products. However, their income may
          not be enough to fulfil their goals. Therefore, we started this project in order to release some financial
          pressures for the students.
        </v-card-text>
      </v-card>

      <v-row>
        <v-col>
          <v-card max-width="900" class="mt-5">
            <v-card-title>
              Project Description
            </v-card-title>
            <v-card-text class="text-body-1">
              <p> 1. Our project is to create a website for HKBU students to create and promote their own services. On the
                website, the HKBU students can create an account for logging in and creating their own services by filling
                out
                the
                service information such as title, description, and what reward they would like to obtain. After that,
                other
                HKBU
                students can view the newly created service on the home page, and they can apply for the service by
                filling
                out the
                form. After other HKBU students apply for the service, their application information will be sent to the
                service provider directly
                through our website email. Finally, the service provider can contact the applicant directly.</p>
              <br />
              <p>
                2. Another function of our website is to allow logged-in HKBU students to manage their financial
                situation. 
                The
                website provides different options for HKBU students to record their monthly savings. They can input their
                monthly expenditure and income, and the website will calculate their savings and present it to a pie chart
                automatically. Therefore, HKBU students can observe their savings monthly and control their expenditures
                intentionally. The following is the preview of the financial report.
              </p>
              <v-img width="100%" class="mt-3" src="../assets/Report.jpg"></v-img>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>


      <v-card max-width="900" class="mt-5">
        <v-card-title>
          Project Excepted Outcomes
        </v-card-title>
        <v-card-text class="text-body-1">
          <p>1. We hope that our HKBU students can reduce some expenditures in their daily lives by providing their
            services on the website and obtaining what they need from the service receivers. Therefore, they may relieve
            some financial pressures through our platform. </p>
          <br />
          <p>2. Also, HKBU students can exchange different skill sets on our platform by applying for different services.
            It is hoped that HKBU students can obtain some new knowledge and skills which are beneficial to their daily
            lives and future careers.</p>
          <br />
          <p>3. Last but not least, we hope that HKBU students can record and manage their financial resources in a more
            organized way. In fact, it is important to know how much you are spending in one day. Therefore, you may
            estimate the monthly expenditure and savings roughly and prepare for any unanticipated events that require a
            certain amount of money.</p>
        </v-card-text>
      </v-card>
    </v-container>
  </main>
  <Footer />
</template>

<script>
import { defineComponent } from 'vue';

// Components
import Navbar from '../components/Navbar/Navbar.vue';
import Footer from '@/components/Footer/Footer.vue';

export default defineComponent({
  name: 'HomeView',

  components: {
    Navbar, Footer
  },
});
</script>