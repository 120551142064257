<template>
    <v-container class="mt-10 pa-5 h-screen">
        <v-card class="pa-10 mx-auto" max-width="700px">
            <v-form>
                <h1 class="mb-10 text-center">Reset Password</h1>
                <h3>Please input your email address</h3>
                <v-text-field type="email" class="mt-8 mb-7" v-model="userEmail" placeholder="2xxxxxxx@life.hkbu.edu.hk"
                    label="Email" variant="outlined" required></v-text-field>

                <v-btn @click="handleForgetPassword" block class="mt-5 mb-2 pa-6 bg-light-blue-darken-4"
                    text="Reset Password"></v-btn>
            </v-form>
        </v-card>

        <v-dialog transition="dialog-top-transition" width="auto" v-model="isShowDialog">
            <template v-slot:default="{ isActive }">
                <v-card>
                    <v-toolbar color="success" title="Reset password link is sent"></v-toolbar>
                    <v-card-text>
                        <div class="text-h5 pa-10">
                            Reset password link is sent to your email. Please click the link to reset your password.
                        </div>
                    </v-card-text>
                    <v-card-actions class="justify-space-between">
                        <v-spacer></v-spacer>
                        <v-btn class="bg-info" @click="isActive.value = false">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>

    </v-container>
</template>


<script setup>
import { ref } from 'vue';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { useRouter } from 'vue-router';

const router = useRouter();
const userEmail = ref("");
const isShowDialog = ref(false);

const auth = getAuth();

const handleForgetPassword = () => {
    sendPasswordResetEmail(auth, userEmail.value)
        .then(() => {
            // display the success reset message to the user and ask them to check email and reset password
            isShowDialog.value = true;
        })
        .catch((error) => {
            // handle the error later
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(error);
        });
}

</script>