<template>
    <v-container class="mt-10">
        <v-row class="justify-center mb-5">
            <CreateCourseForm />
        </v-row>
    </v-container>

    <v-container class="h-screen">
        <v-row class="d-flex justify-content-center mx-auto">
            <v-col cols="12" sm="6" md="4" class="mb-5" v-for="course in courseData" :key="course.id">
                <CourseCard :course="course" />
            </v-col>
        </v-row>
    </v-container>

</template>


<script setup>
import CourseCard from './UserCourseCard.vue';
import CreateCourseForm from './CreateCourseForm.vue';

import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { query, collection, getDocs, where, orderBy } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import db from '../../main';

const items = ref([1, 2, 3, 4, 6, 7, 8]);
const userEmail = ref('');
const auth = getAuth();

const courseData = ref([]);

const reloadPage = () => {
    router.push('/service');
}

// get user email
const getUserEmail = () => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
            userEmail.value = user.email;
            getUserCourses();
        }
    });
}

// get user created courses
const getUserCourses = async () => {
    const q = query(collection(db, "services"), where("creatorEmail", "==", userEmail.value));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        let course = doc.data();                    // get the orginal data
        course.id = doc.id;                         // get and assign the single id for the data
        courseData.value.push(course)               // push the single data to the total array for displaying later
    });
}

onMounted(() => {
    getUserEmail();
})

</script>