import firebase from '@/firebase'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { computed, onMounted, onUnmounted } from 'vue';

/**
 * gcap_website version
 * /
// export const firebaseConfig = {
//     apiKey: "AIzaSyDeZhhfEESM1ENmE_XIZMbC90TVP16sz78",
//     authDomain: "gcap3166-api.firebaseapp.com",
//     projectId: "gcap3166-api",
//     storageBucket: "gcap3166-api.appspot.com",
//     messagingSenderId: "159874705093",
//     appId: "1:159874705093:web:0260c8eb35f7aa24e6b2ac",
//     measurementId: "G-8STKGCD288"
// };

/**
 * wanderershk version
 */
export const firebaseConfig = {
    apiKey: "AIzaSyAUDFyOxqoNd11lNyw36ppKyauJZ-ry6ZM",
    authDomain: "wanderershk-c8885.firebaseapp.com",
    projectId: "wanderershk-c8885",
    storageBucket: "wanderershk-c8885.appspot.com",
    messagingSenderId: "657752921128",
    appId: "1:657752921128:web:47c0686d66d3bf9aa6911d",
    measurementId: "G-KH5VLQRPK0"
  };

// const firebaseApp = initializeApp(firebaseConfig);
// export default firebaseApp.firestore();