<template>
    <v-container class="mt-5" fluid="true">
        <v-row class="justify-space-between">
            <v-col xm="auto" sm="6" lg="4">
                <v-text-field v-model="searchValue" label="Search" prepend-inner-icon="mdi-magnify" filled dense
                    variant="solo"></v-text-field>
            </v-col>
            <v-col xm="auto" sm="4" lg="2">
                <v-select v-model="filterValue" label="Filter" :items="filterItems" variant="solo"></v-select>
            </v-col>
        </v-row>
    </v-container>

    <v-container fluid="true">
        <v-row class="d-flex justify-content-center mx-auto h-screen">
            <v-col cols="12" sm="6" md="4" class="mb-5" v-for="course in courseData" :key="course.id">
                <CourseCard :course="course" />
            </v-col>
        </v-row>
    </v-container>

    <!-- Add the pagination later if there are many cards -->
    <!-- <v-container class="mb-10">
        <v-pagination class="overflow-auto" :length="30" total-visible="5"></v-pagination>
    </v-container> -->
</template>


<script setup>
import CourseCard from './CourseCard.vue';
import { onMounted, ref, watch } from 'vue';
import { collection, query, orderBy, getDocs } from "firebase/firestore";
import db from '../../main';

const items = ref([1, 2, 3, 4, 6, 7, 8]);
const filterItems = ref(["All", "Academic", "Language", "Hobby", "Sports", "Work Skills"]);
let allCourseData = ref([]);
let courseData = ref([]);
let filteredCourseData = ref([]);

const searchValue = ref('');
const filterValue = ref('');

// can get data
const getAllCourses = async () => {
    // const querySnapshot = query(collection(db, "course_testing"), orderBy("updatedDate"));
    const q = query(collection(db, "services"), orderBy("updatedDate", "desc"));
    const querySnapshot = await getDocs(q);
    // const querySnapshot = await getDocs(collection(db, "course_testing"));
    querySnapshot.forEach((doc) => {
        let course = doc.data();                    // get the orginal data
        course.id = doc.id;                         // get and assign the single id for the data
        courseData.value.push(course)               // push the single data to the total array for displaying later

        allCourseData.value.push(course)            // for course searching and filtering
    });
}

onMounted(async () => {
    await getAllCourses();
})

// 
watch(searchValue, () => {
    // reset the coures data
    courseData.value = allCourseData.value;

    filteredCourseData.value = [];
    const courses = courseData.value
    for (let index in courses) {
        if (courses[index].title.toLowerCase().includes(searchValue.value.toLowerCase())) {
            filteredCourseData.value.push(courses[index]);
        }
    }

    courseData.value = filteredCourseData.value;
})

// 
watch(filterValue, () => {
    // reset the coures data
    courseData.value = allCourseData.value;

    if (filterValue.value == 'All') {
        return;
    }

    filteredCourseData.value = [];
    const courses = courseData.value
    for (let index in courses) {
        if (courses[index].category == filterValue.value) {
            filteredCourseData.value.push(courses[index]);
        }
    }

    courseData.value = filteredCourseData.value;
})

</script>