<template>
  <Navbar />
  <CourseCards />
  <Footer />
</template>

<script>
import { defineComponent } from 'vue';

// Components
import Navbar from '../components/Navbar/Navbar.vue';
import Footer  from '@/components/Footer/Footer.vue';
import CourseCards from '../components/Home/CourseCards.vue';

export default defineComponent({
  name: 'HomeView',

  components: {
    Navbar, CourseCards, Footer
  },
});
</script>
