<template>
    <Navbar />
    <RegisterFormVue />
    <Footer />
</template>
  
<script>

import { defineComponent } from 'vue';
import Navbar from '../components/Navbar/Navbar.vue';
import RegisterFormVue from '@/components/Register/RegisterForm.vue';
import Footer from '@/components/Footer/Footer.vue';

export default defineComponent({
    name: 'LoginView',

    components: {
        Navbar, RegisterFormVue, Footer
    },
});

</script>