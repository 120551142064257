<template>
    <v-dialog v-model="isEditItem" persistent width="1024">
        <template v-slot:activator="{ props }">
            <v-btn class="bg-info" @click="isEditItem = true" v-bind="props">Edit</v-btn>
        </template>
        <v-card>
            <v-card-title class="text-center mt-5">
                <span class="text-h5">Edit {{ source }} </span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="source" label="Source" readonly variant="outlined"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="value" label="Value" variant="outlined" required></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="d-flex justify-space-between">
                <v-btn class="bg-red" variant="text" @click="isEditItem = false">
                    Cancel
                </v-btn>
                <v-btn class="bg-info" variant="text" @click="update">
                    Update
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
import { ref } from 'vue';

const isEditItem = ref(false);

const props = defineProps(['type', 'source', 'value'])
const emit = defineEmits(['updateItem']);

const type = ref(props.type);
const source = ref(props.source);
const value = ref(props.value);

const update = () => {
    emit('updateItem', type.value, source.value, value.value);
    isEditItem.value = false;
}

</script>