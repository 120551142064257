<template>
    <v-card max-height="400" min-height="300" max-width="500" class="mx-auto fill-height d-flex flex-column justify-space-between">
        <v-card-item class="flex-grow-1 d-flex flex-column align-self-start">
            <v-card-title>{{ course.title }}</v-card-title>
            <v-card-subtitle class="mb-2">{{ course.subtitle }}</v-card-subtitle>
            <div class="mb-3">
                <p class="text-h6">Description</p>
                <p>{{ course.desc.length > 500 ? course.desc.slice(0, 500) + "..." : course.desc }}</p>
            </div>
            <div class="mb-3">
                <p class="text-h6">Desired Reward</p>
                <p>{{ course.reward }}</p>
            </div>
            <!-- <v-list-item v-for="reward in course.rewards" :key="reward">
                {{ reward }}
            </v-list-item> -->
        </v-card-item>

        <v-card-actions class="d-flex justify-space-between">
            <v-btn class="bg-red" @click="isShowDelete = true">Delete</v-btn>
            <EditCourseForm :course="course" />
        </v-card-actions>
    </v-card>

    <!-- dialog for deleting the post -->
    <v-dialog transition="dialog-top-transition" width="auto" v-model="isShowDelete">
        <template v-slot:default="{ isActive }">
            <v-card>
                <v-toolbar color="red" title="Delete Service"></v-toolbar>
                <v-card-text>
                    <div class="text-h5 pa-10">Are you sure to delete the "{{ course.title }}" service</div>
                </v-card-text>
                <v-card-actions class="justify-space-between">
                    <v-btn class="bg-info" @click="isActive.value = false">Close</v-btn>
                    <v-btn class="bg-red" @click="deleteCourse" >Delete</v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>

    <v-dialog transition="dialog-top-transition" width="auto" v-model="isShowMessage">
        <template v-slot:default="{ isActive }">
            <v-card>
                <v-toolbar color="success" title="Delete Service Success"></v-toolbar>
                <v-card-text>
                    <div class="text-h5 pa-10">Successfully delete the service</div>
                </v-card-text>
                <v-card-actions class="justify-space-between">
                    <v-btn class="bg-info" @click="isActive.value = false" href="/service">Close</v-btn>
                    <v-btn class="bg-info" @click="isActive.value = false" href="/service">Service</v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>

</template>

<script setup>
import { ref } from 'vue';
import EditCourseForm from './EditCourseForm.vue';

import db from '../../main';
import { doc, deleteDoc, collection } from "firebase/firestore";

const isShowDelete = ref(false);
const isShowMessage = ref(false);

const props = defineProps(['course'])
const course = ref(props.course);

course.value.desc = course.value.desc.replace("\n", "\n");

const deleteCourse = async () => {
    const targetDoc = doc(db, "services", course.value.id);
    await deleteDoc(targetDoc);
    isShowDelete.value = false;
    isShowMessage.value = true;
}

</script>