<template>
    <v-app-bar color="light-blue-darken-4">
        <v-app-bar-title class="">Wanderershk</v-app-bar-title>

        <v-toolbar-items class="justify-content-start d-none d-md-flex">
            <v-btn href="/">Home</v-btn>
            <v-btn href="/about">About</v-btn>
            <!-- <v-btn href="/contact">Contact</v-btn> -->
            <!-- <v-btn>Finance Calculator</v-btn> -->
            <v-btn v-if="isLoggedIn" href="/service">My Service</v-btn>
            <v-btn v-if="isLoggedIn" href="/report">Saving Report</v-btn>
            <v-btn v-if="!isLoggedIn" href="/register">Register</v-btn>
            <v-btn v-if="!isLoggedIn" href="/login">Login</v-btn>
            <v-btn v-if="isLoggedIn" @click="handleSignOut">Logout</v-btn>
        </v-toolbar-items>

        <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer" class="d-flex d-md-none"></v-app-bar-nav-icon>
    </v-app-bar>

    <!-- hardcode for the mobile view -->
    <v-navigation-drawer v-model="drawer" location="right" temporary class="d-md-none">
        <v-list density="compact" nav>
            <v-list-item prepend-icon="mdi-home" title="Home" class="mt-3" href="/"></v-list-item>
            <v-list-item prepend-icon="mdi-information" title="About" href="/about"></v-list-item>
            <!-- <v-list-item prepend-icon="mdi-account-box" title="Contact" href="/contact"></v-list-item> -->
            <!-- <v-list-item prepend-icon="mdi-calculator" title="Finance Calculator" ></v-list-item> -->
            <v-list-item prepend-icon="mdi-book" title="My service" v-if="isLoggedIn" href="/service"></v-list-item>
            <v-list-item prepend-icon="mdi-chart-pie" title="Saving Report" v-if="isLoggedIn" href="/report"></v-list-item>
        </v-list>

        <template v-slot:append>
            <div class="pa-5 mb-10">
                <v-btn block class="mb-5 bg-light-blue-darken-2" v-if="!isLoggedIn" href="/register" >Register</v-btn>
                <v-btn block class="bg-light-blue-darken-4" v-if="!isLoggedIn" href="/login" >Login</v-btn>
                <v-btn @click="handleSignOut" class="bg-red-darken-1" block v-if="isLoggedIn" >Logout</v-btn>
            </div>
        </template>
    </v-navigation-drawer>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useRouter } from 'vue-router';
    
const router = useRouter();
const drawer = ref(false);    
const isLoggedIn = ref(false);

let auth;
onMounted(() => {
    auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        if (user) {
            isLoggedIn.value = true;
        } else {
            isLoggedIn.value = false;
        }
    });
})

const handleSignOut = () => {
    signOut(auth).then(() => {
        router.push('/');
    });
};

</script>