
<template>
    <v-dialog v-model="isShowDialog" persistent width="1024">
        <template v-slot:activator="{ props }">
            <v-btn class="bg-light-blue" v-bind="props">MORE INFO</v-btn>
        </template>
        <v-card>
            <v-card-title class="text-h4 text-center mt-3">
                {{  course.title }}
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="6" md="8">
                            <v-text-field v-model="course.title" label="Service Title" variant="outlined" readonly="true"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="course.subtitle" label="Service Subtitle" variant="outlined" readonly="true" ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea v-model="course.desc" label="Service Description" variant="outlined" readonly="true" ></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-select v-model="course.category"
                                label="Category" variant="outlined" readonly="true" ></v-select>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field v-model="course.reward" label="Desired Reward" variant="outlined"
                            readonly="true"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field v-model="course.location" label="Location" variant="outlined"
                            readonly="true"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea v-model="course.remark" label="Service Creator's Remark" variant="outlined" readonly="true" ></v-textarea>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="d-flex justify-space-between pa-10">
                <v-btn class="bg-red" @click="isShowDialog = false">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
import { ref } from "vue";
const props = defineProps(['course'])
const course = ref(props.course);
const isShowDialog = ref(false);

</script>