
<template>
    <v-dialog v-model="isShowDialog" persistent width="1024">
        <template v-slot:activator="{ props }">
            <v-btn class="bg-info px-5" v-bind="props">Apply</v-btn>
        </template>
        <v-card>
            <v-card-title class="text-h4 text-center mt-3">
                "{{ course.title }}" Apply Form
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="name" :rules="nameRules" label="Your name" variant="outlined"
                                hint="The name that the service provider to recognize you." required></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="email" label="Email" variant="outlined" required :rules="emailRules"
                                hint="Email to be sent to the service provider"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea v-model="message" variant="outlined"
                                label="Message to the service's provider (optional)"></v-textarea>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="otherContact" variant="outlined"
                                label="Other contact (optional)"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-checkbox v-model="isAgreeTerms" :rules="termsRules" class="mb-5" label="Agree Terms"
                                required>
                                <template v-slot:label>
                                    <div>
                                        Agree
                                        <Terms />
                                    </div>
                                </template>
                            </v-checkbox>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="d-flex justify-space-between pa-10">
                <v-btn class="bg-red" @click="isShowDialog = false">
                    Cancel
                </v-btn>
                <v-btn class="bg-info px-5 text-center" @click="sendEmailToCourseCreator">
                    Submit
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog transition="dialog-top-transition" width="auto" v-model="isSent">
        <template v-slot:default="{ isActive }">
            <v-card>
                <v-toolbar color="success" title="Apply Service Success"></v-toolbar>
                <v-card-text>
                    <div class="text-h5 pa-10">Successfully apply the {{ course.title }} service</div>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn class="bg-info" @click="isActive.value = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>

    <v-dialog transition="dialog-top-transition" width="auto" v-model="isShowError">
        <template v-slot:default="{ isActive }">
            <v-card>
                <v-toolbar class="bg-red" title="Register Failed"></v-toolbar>
                <v-card-text>
                    <div class="text-h5 pa-10">{{ errorMessage }}</div>
                </v-card-text>
                <v-card-actions class="">
                    <v-spacer></v-spacer>
                    <v-btn class="bg-red" @click="isActive.value = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>


<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import db from '../../main';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, addDoc } from "firebase/firestore";
import Terms from '../Footer/Terms.vue';

const props = defineProps(['course'])
const course = ref(props.course);

const isShowDialog = ref(false);
const isShowError = ref(false);
const errorMessage = ref('');

const isSent = ref(false);

// for form apply
const name = ref('');
const email = ref('');
const message = ref('');
const otherContact = ref('');
const isAgreeTerms = ref(false);

const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

// get course creator info
const creatorEmail = ref(course.value.creatorEmail);

// my testing email account
// const serviceId = ref('service_6admuqn');
// const templateId = ref('template_2r8ns34');
// const publicKey = ref('kfF4HcVz7Hofnfgi2');

// real email account
const serviceId = ref('service_3hbulge');
const templateId = ref('template_af5q06j');
const publicKey = ref('dcTSvXNhsxYS-LIhn');

/// for sending email 
const emailjs = require('emailjs-com');
const emailParams = ref({
    serviceName: course.value.title,
    senderName: name.value,
    senderEmail: email.value,
    senderMessage: message.value,
    senderContact: otherContact.value,
    to_name: creatorEmail.value
});

// send the applicant infomation to the course creator directly through emailjs extention
const sendEmailToCourseCreator = () => {
    emailParams.value = {
        serviceName: course.value.title,
        senderName: name.value,
        senderEmail: email.value,
        senderMessage: message.value,
        senderContact: otherContact.value,
        to_email: creatorEmail.value
    }

    if (emailParams.value.senderName == null || emailParams.value.senderName == '') {
        errorMessage.value = "Please input your name.";
        isShowError.value = true;
        return;
    }

    if (emailParams.value.senderEmail == null || emailParams.value.senderEmail == '') {
        errorMessage.value = "Please input your email.";
        isShowError.value = true;
        return;
    }

    if (!emailRegex.test(emailParams.value.senderEmail)) {
        errorMessage.value = "Please input valid email.";
        isShowError.value = true;
        return;
    }

    if (isAgreeTerms.value != true) {
        errorMessage.value = "Please argee the terms.";
        isShowError.value = true;
        return;
    }

    emailjs.send(serviceId.value, templateId.value, emailParams.value, publicKey.value)
        .then((res) => {
            addApplyRecord();
            name.value = null;
            email.value = null;
            message.value = null;
            otherContact.value = null;
            isSent.value = true;
        }).catch((err) => {
            console.log(err);
        });
}

// for saving the record
const addApplyRecord = async () => {
    const docRef = await addDoc(collection(db, "apply_service"), {
        serviceProvider: {
            serviceName: course.value.title,
            email: creatorEmail.value
        },
        sender: {
            senderName: name.value,
            senderEmail: email.value,
            senderMessage: message.value,
            senderContact: otherContact.value,
        },
        createdDate: new Date(),
        updatedDate: new Date(),
    });
}

const termsRules = [
    value => {
        if (value != true) {
            return "You must agree the terms"
        }
    }
];

const nameRules = [
    value => {
        if (value != '' && value != null) {
            return true;
        }
        return "Please input your name.";
    }
]

const emailRules = [
    value => {
        if (emailRegex.test(value)) {
            return true;
        }
        return "Please input valid email.";
    }
]

</script>