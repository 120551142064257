<template>
    <v-dialog v-model="isShowDialog" persistent width="1024">
        <template v-slot:activator="{ props }">
            <v-btn color="info" v-bind="props">Create new service</v-btn>
        </template>
        <v-card>
            <v-card-title class="text-h4 text-center mt-3">
                Create Service Form
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="6" md="8">
                            <v-text-field v-model="title" label="Service Title" variant="outlined" required></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="subtitle" label="Service Subtitle" variant="outlined"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea v-model="desc" label="Service Description" variant="outlined" required></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-select v-model="category" :items="['Academic', 'Language', 'Hobby', 'Sports', 'Work Skills']"
                                label="Category" variant="outlined" required></v-select>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field v-model="reward" label="Desired Reward" variant="outlined"
                                multiple></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field v-model="location" label="Location" variant="outlined"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea v-model="remark" label="Remarks" variant="outlined"></v-textarea>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="d-flex justify-space-between pa-10">
                <v-btn class="bg-red" @click="isShowDialog = false">
                    Close
                </v-btn>
                <v-btn class="bg-info px-5 text-center" @click="createNewCourse">
                    Create
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog transition="dialog-top-transition" width="auto" v-model="isCreated">
        <template v-slot:default="{ isActive }">
            <v-card>
                <v-toolbar color="success" title="Create Service Success"></v-toolbar>
                <v-card-text>
                    <div class="text-h5 pa-10">Successfully create the service</div>
                </v-card-text>
                <v-card-actions class="justify-space-between">
                    <v-btn class="bg-info" @click="isActive.value = false" href="/service">Close</v-btn>
                    <v-btn class="bg-info" @click="isActive.value = false" href="/service">See the new created
                        service</v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import db from '../../main';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, addDoc } from "firebase/firestore";

const props = defineProps(['userEmail'])
const userEmail = ref(props.userEmail);

const isShowDialog = ref(false);
const isCreated = ref(false);

const title = ref('');
const subtitle = ref('');
const desc = ref('');
const reward = ref('')
const category = ref('');
const location = ref('');
const remark = ref('');

// get user email
const auth = getAuth();
const getUserEmail = () => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
            userEmail.value = user.email;
        }
    });
}

// create the new course in the db
const createNewCourse = async () => {
    const createdDate = new Date();
    const updatedDate = new Date();

    if (title.value == '' || subtitle.value == '' || desc.value == '' || reward.value == '' || category.value == '') {
        console.log("All values are required");
    }

    let formatedDesc = desc.value;
    formatedDesc = formatedDesc.replaceAll("\n", "\\n");
    
    try {
        const docRef = await addDoc(collection(db, "services"), {
            title: title.value,
            subtitle: subtitle.value,
            creatorEmail: userEmail.value,
            desc: formatedDesc,
            category: category.value,
            reward: reward.value,
            location: location.value,
            remark: remark.value,
            createdDate,
            updatedDate,
        });
        isShowDialog.value = false;
        isCreated.value = true;

        // for backup purpose
        const docRefBackup = await addDoc(collection(db, "services_backup"), {
            title: title.value,
            subtitle: subtitle.value,
            creatorEmail: userEmail.value,
            desc: formatedDesc,
            category: category.value,
            reward: reward.value,
            location: location.value,
            remark: remark.value,
            createdDate,
            updatedDate,
        });

    } catch (err) {
        console.log(err);
    }
}

onMounted(() => {
    getUserEmail();
})

</script>