<template>
    <v-container>
        <v-row class="justify-center">
            <v-col cols="12" sm="8" class="d-flex justify-lg-space-between flex-grow-1 w-100">
                <v-select v-model="selectYear" label="Year" :items="yearList" class="mt-5" variant="solo"></v-select>
                <v-select v-model="selectMonth" label="Month" :items="monthList" class="mt-5 ml-5" variant="solo"></v-select>
            </v-col>
            <v-col cols="12" sm="4" class="d-flex justify-center align-center">
                <v-menu :location="'bottom'">
                    <template v-slot:activator="{ props }">
                        <v-btn color="info" v-bind="props" class="w-50 mb-3">Manage</v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                            <v-dialog v-model="isAddIncome" persistent width="1024">
                                <template v-slot:activator="{ props }">
                                    <v-btn class="w-100 bg-info" @click="isAddIncome = true" v-bind="props">Add
                                        income</v-btn>
                                </template>
                                <v-card>
                                    <v-card-title class="text-center">
                                        <span class="text-h5">{{ selectYear }} - {{ selectMonth }} Report Add Income </span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" sm="6">
                                                    <v-text-field v-model="incomeSource" label="Income Source" variant="outlined"
                                                        required></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6">
                                                    <v-text-field v-model="incomeValue" label="Value" variant="outlined"
                                                        required></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions class="d-flex justify-space-between">
                                        <v-btn class="bg-red" variant="text" @click="isAddIncome = false">
                                            Cancel
                                        </v-btn>
                                        <v-btn class="bg-info" variant="text" @click="addIncome">
                                            Create
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-list-item>
                        <v-list-item>
                            <v-dialog v-model="isAddExpenditure" persistent width="1024">
                                <template v-slot:activator="{ props }">
                                    <v-btn class="w-100 bg-info" @click="isAddExpenditure = true" v-bind="props" variant="outlined">Add
                                        expenditure</v-btn>
                                </template>
                                <v-card>
                                    <v-card-title class="text-center">
                                        <span class="text-h5">{{ selectYear }} - {{ selectMonth }} Report Add
                                            expenditure</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" sm="6">
                                                    <v-text-field v-model="expenditureSource" label="Expenditure Source" variant="outlined"
                                                        required></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6">
                                                    <v-text-field v-model="expenditureValue" label="Value" variant="outlined"
                                                        required></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions class="d-flex justify-space-between">
                                        <v-btn class="bg-red" variant="text" @click="isAddExpenditure = false">
                                            Cancel
                                        </v-btn>
                                        <v-btn class="bg-info" variant="text" @click="addExpenditure">
                                            Create
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-list-item>
                        <v-list-item>
                            <v-dialog v-model="isEditCurrentItem" persistent width="1024">
                                <template v-slot:activator="{ props }">
                                    <v-btn class="w-100 bg-info" @click="displayMonthlyReportDetail" v-bind="props">Edit
                                        current value</v-btn>
                                </template>
                                <v-card>
                                    <v-container>
                                        <v-row>
                                            <v-col>
                                                <h2 class="text-center">Income table</h2>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col><v-spacer>Income Title: </v-spacer></v-col>
                                            <v-col><v-spacer>Value: </v-spacer></v-col>
                                            <v-col><v-spacer class="d-none d-sm-block">Action:</v-spacer></v-col>
                                        </v-row>
                                        <v-row v-for="value, key in incomeDetail" :key="key">
                                            <v-col><v-spacer>{{ key }}</v-spacer></v-col>
                                            <v-col><v-spacer>{{ value }}</v-spacer></v-col>
                                            <v-col>
                                                <v-spacer class="d-flex justify-space-between">
                                                    <DeleteItem :type="'income'" :source="key" :value="value"
                                                        @deleteItem="deleteCurrentItem" />
                                                    <EditItem :type="'income'" :source="key" :value="value"
                                                        @updateItem="updateCurrentItem" />
                                                </v-spacer>
                                            </v-col>
                                        </v-row>
                                        <v-spacer></v-spacer>
                                        <v-row>
                                            <v-col>
                                                <h2 class="text-center">Expenditure table</h2>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col><v-spacer>Expenditure Title:</v-spacer> </v-col>
                                            <v-col><v-spacer>Value:</v-spacer></v-col>
                                            <v-col><v-spacer>Action: </v-spacer></v-col>
                                        </v-row>
                                        <v-row v-for="value, key in expenditureDetail" :key="key">
                                            <v-col><v-spacer>{{ key }}</v-spacer></v-col>
                                            <v-col><v-spacer>{{ value }}</v-spacer></v-col>
                                            <v-col>
                                                <v-spacer class="d-flex justify-space-between align-center">
                                                    <DeleteItem :type="'expenditure'" :source="key" :value="value"
                                                        @deleteItem="deleteCurrentItem" />
                                                    <EditItem :type="'expenditure'" :source="key" :value="value"
                                                        @updateItem="updateCurrentItem" />
                                                </v-spacer>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                    <v-card-actions class="d-flex justify-end mt-3">
                                        <v-btn class="bg-red" variant="text" @click="isEditCurrentItem = false">
                                            Close
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>
    </v-container>

    <v-container>
        <v-row>
            <v-col>
                <h1 class="text-center">{{ selectYear == '' ? '' : selectYear }}-{{ selectMonth == '' ? '' : selectMonth }}
                    Report</h1>
            </v-col>
        </v-row>
    </v-container>

    <v-sheet max-height="800" class="d-flex justify-center align-center">
        <Pie :options="options" :data="data" ref="pie" />
    </v-sheet>

    <v-container class="mb-10">
        <v-row class="justify-center">
            <v-col sm="12" class="d-flex justify-center align-center">
                <p class="d-inline-block text-h5 mr-10 text-end">Total Saving:</p>
                <p class="d-inline-block text-h5 text-start">HKD$ {{ savingAmount }}</p>
            </v-col>
        </v-row>

    </v-container>
</template>
    
<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { Pie } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale, PieController } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, PieController)

const pie = ref();
const labelsValue = ref([]);

const data = computed(() => ({
    labels: labelsValue.value,
    datasets: [{
        data: [],
        backgroundColor: [
            getRandomColor(),
            getRandomColor(),
            getRandomColor(),
        ],
        hoverOffset: 8,
    }]
}));

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

const getRandomColor = () => {
    const max = 266;

    const r = getRandomInt(max);
    const g = getRandomInt(max);
    const b = getRandomInt(max);

    const color = `rgb(${r}, ${g}, ${b})`

    return color;
}

const options = ref({
    responsive: true,
    plugins: {
        legend: {
            labels: {
                font: {
                    size: 16
                }
            },
            position: 'top',
        },
    }
});

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, query, collection, addDoc, getDocs, deleteField, where, updateDoc } from 'firebase/firestore';
import db from '../../main';

import DeleteItem from './DeleteItem.vue';
import EditItem from './EditItem.vue';

const userEmail = ref('');
const auth = getAuth();
const personalReports = ref({});    // get the collection from the db
const reports = ref({});
const reportsId = ref();

const yearList = ref([2023, 2024, 2025]);
const monthList = ref([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
const selectYear = ref();
const selectMonth = ref();
const savingAmount = ref(0);

// for open dialog form 
const isAddIncome = ref(false);
const incomeSource = ref('');
const incomeValue = ref(0);

const isAddExpenditure = ref(false);
const expenditureSource = ref('');
const expenditureValue = ref(0);

const isEditCurrentItem = ref(false);
const incomeDetail = ref([]);
const expenditureDetail = ref([]);

// get user email
const getUserEmail = () => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
            userEmail.value = user.email;
            getAllReports();
        }
    });
}

// create the reports collection when the user is first time to use
const createAllReports = async () => {

    const reportContent = {};
    const years = yearList.value;
    const months = monthList.value;

    // assign the years to the collection
    for (const year of years) {
        reportContent[year] = {};
        for (const month of months) {
            reportContent[year][month] = {
                expenditure: {},
                income: {},
                saving: 0
            }
        }
    }

    const data = {
        email: userEmail.value,
        reports: reportContent
    }

    // add the document to db
    const docRef = await addDoc(collection(db, "reports"), data);

    getAllReports();
}

// get all reports data from the db
const getAllReports = async () => {
    const q = query(collection(db, "reports"), where("email", "==", userEmail.value));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
        createAllReports();
        return;
    }

    querySnapshot.forEach((doc) => {
        personalReports.value = doc.data();
        reports.value = personalReports.value.reports;
        reportsId.value = doc.id;
    });

    const today = new Date();
    const year = today.getUTCFullYear();
    const month = today.getMonth() + 1;

    // set the first report
    selectYear.value = year;
    selectMonth.value = month;
    addDataToPie();
}

// get report by year and month 
const getMonthlyReport = (year, month) => {
    if (month == null || month == undefined) {
        return;
    }

    let targetReport = reports.value;

    try {
        targetReport = targetReport[year][month];
    } catch (err) {
        console.log(err);
        targetReport = null;
    }

    return targetReport;
}

// create add new income record
const addIncome = async () => {
    const targetYear = selectYear.value;
    const targetMonth = selectMonth.value;

    if (targetYear == null || targetMonth == null || targetMonth == undefined || targetYear == undefined) {
        console.error('No value for insert');
        return;
    }

    const incomeName = incomeSource.value;
    const incomeNum = incomeValue.value;

    const userReportDocRef = doc(db, "reports", reportsId.value);

    // add the new income
    reports.value[targetYear][targetMonth]["income"][incomeName] = parseInt(incomeNum);

    // caclulate the saving
    reports.value[targetYear][targetMonth]["saving"] = calculateSaving(reports.value[targetYear][targetMonth]);

    try {
        await updateDoc(userReportDocRef, {
            email: userEmail.value,
            reports: reports.value
        })
    } catch (err) {
        console.log(err);
    }

    isAddIncome.value = false;
    incomeSource.value = '';
    incomeValue.value = '';

    getAllReports();
    addDataToPie();
}

// create add new expenditure record
const addExpenditure = async () => {
    const targetYear = selectYear.value;
    const targetMonth = selectMonth.value;

    if (targetYear == null || targetMonth == null || targetMonth == undefined || targetYear == undefined) {
        console.error('No value for insert');
        return;
    }

    const expenditureName = expenditureSource.value;
    const expenditureNum = expenditureValue.value;

    const userReportDocRef = doc(db, "reports", reportsId.value);

    reports.value[targetYear][targetMonth]["expenditure"][expenditureName] = parseInt(expenditureNum);

    reports.value[targetYear][targetMonth]["saving"] = calculateSaving(reports.value[targetYear][targetMonth]);

    try {
        await updateDoc(userReportDocRef, {
            email: userEmail.value,
            reports: reports.value
        })
    } catch (err) {
        console.log(err);
    }

    isAddExpenditure.value = false;
    expenditureSource.value = '';
    expenditureValue.value = '';

    getAllReports();
    addDataToPie();
}

// update the existing items record
const updateCurrentItem = async (type, source, value) => {
    const targetYear = selectYear.value;
    const targetMonth = selectMonth.value;

    try {
        const userReportDocRef = doc(db, "reports", reportsId.value);

        // get the new value
        reports.value[targetYear][targetMonth][type][source] = parseInt(value);

        // caclulate the saving
        reports.value[targetYear][targetMonth]["saving"] = calculateSaving(reports.value[targetYear][targetMonth]);

        await updateDoc(userReportDocRef, {
            email: userEmail.value,
            reports: reports.value
        });

        isEditCurrentItem.value = false;

        getAllReports();
        addDataToPie();

    } catch (err) {
        console.log(err);
    }
}

// delete the exitsting iiems record
const deleteCurrentItem = async (type, source, value) => {
    const targetYear = selectYear.value;
    const targetMonth = selectMonth.value;

    try {
        const userReportDocRef = doc(db, "reports", reportsId.value);

        // delete the target object and avlue
        delete reports.value[targetYear][targetMonth][type][source];

        // caclulate the new saving
        reports.value[targetYear][targetMonth]["saving"] = calculateSaving(reports.value[targetYear][targetMonth]);

        await updateDoc(userReportDocRef, {
            email: userEmail.value,
            reports: reports.value
        });

        isEditCurrentItem.value = false;

        getAllReports();
        addDataToPie();

    } catch (err) {
        console.log(err);
    }
}

// calculate the saving value
const calculateSaving = (monthlyRecord) => {
    let saving = 0;

    const income = monthlyRecord.income;
    const expenditure = monthlyRecord.expenditure;

    for (let index in income) {
        saving += income[index];
    }

    for (let index in expenditure) {
        saving -= expenditure[index];
    }

    return saving;
}

// 
const addDataToPie = () => {
    const targetReport = getMonthlyReport(selectYear.value, selectMonth.value);

    // reset all current pie data
    labelsValue.value = [];
    data.value.datasets[0].data = [];
    data.value.datasets[0].backgroundColor = [];

    if (targetReport == '' || targetReport == null || targetReport == undefined) {
        console.error("No result");
        return;
    }

    const income = targetReport.income;
    const expenditure = targetReport.expenditure;
    const saving = targetReport.saving;

    for (const item in income) {

        // check the pie chart has the same value or not
        if (!labelsValue.value.includes(item)) {

            // add the income label
            labelsValue.value.push(item)

            // add the income value
            data.value.datasets[0].data.push(income[item]);

            // add new color
            data.value.datasets[0].backgroundColor.push(getRandomColor());
        }
    }

    for (const item in expenditure) {

        // check the pie chart has the same value or not
        if (!labelsValue.value.includes(item)) {

            // add the expenditure label
            labelsValue.value.push(item)

            // add the expenditure value
            data.value.datasets[0].data.push(expenditure[item]);

            // add new color
            data.value.datasets[0].backgroundColor.push(getRandomColor());
        }
    }

    updateReport();
    savingAmount.value = saving;
}

const displayMonthlyReportDetail = computed(() => {
    const targetReport = getMonthlyReport(selectYear.value, selectMonth.value);

    const income = targetReport.income;
    const expenditure = targetReport.expenditure;

    incomeDetail.value = income;

    expenditureDetail.value = expenditure;
});

watch(selectMonth, () => {
    addDataToPie();
});

const updateReport = () => {
    const chartInstance = pie.value.chart;
    if (chartInstance != null && chartInstance != undefined) {
        chartInstance.update();
    }
}

onMounted(() => {
    getUserEmail();
})

</script>