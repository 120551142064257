import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue';
import AboutView from '../views/AboutView.vue';
import ContactView from '../views/ContactView.vue';
import LoginView from '../views/LoginView.vue';
import LoginResetView from '../views/LoginResetView.vue';
import RegisterView from '../views/RegisterView.vue';
import CourseView from '../views/CourseView.vue';
import ReportView from '../views/ReportView.vue';

import { getAuth, onAuthStateChanged } from "firebase/auth";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  }, 
  {
    path: '/login/reset',
    name: 'loginReset',
    component: LoginResetView
  }, 
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/service',
    name: 'service',
    component: CourseView,
    meta: {
      requiresAuth: true,
    }
  }, 
  {
    path: '/report',
    name: 'report',
    component: ReportView,
    meta: {
      requiresAuth: true,
    }
  }, 
  {
    path: '/test',
    name: 'testing page',
    component: () => import(/* webpackChunkName: "about" */ '../views/TestingView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const removeListener = onAuthStateChanged(
      getAuth(),
      (user) => {
        removeListener();
        resolve(user);
      },
      reject
    )
  })
}

// check for the user auth
router.beforeEach(async(to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (await getCurrentUser()) {
      next();
    } else {
      alert("you dont have access");
      next("/");
    }
  } else {
    next();
  }
});

export default router
