<template>
  <Navbar />
  <PersonalReport />
  <Footer />
</template>
  
<script>

import { defineComponent } from 'vue';

import Navbar from '../components/Navbar/Navbar.vue';
import PersonalReport from '../components/Report/PersonalReport.vue';
import Footer from '@/components/Footer/Footer.vue';

export default defineComponent({
  name: 'ReportView',

  components: {
    Navbar, PersonalReport, Footer
  },
});

</script>