<template>
    <v-btn class="bg-red" @click="isDeleteItem = true">Delete</v-btn>

    <!-- dialog for deleting the item -->
    <v-dialog transition="dialog-top-transition" width="auto" v-model="isDeleteItem">
        <template v-slot:default="{ isActive }">
            <v-card>
                <v-toolbar color="red" title="Delete Confirmation">
                </v-toolbar>
                <v-card-text>
                    <div class="text-h5 pa-10">Are you sure to delete
                        the {{ source }} with $ {{ value }}</div>
                </v-card-text>
                <v-card-actions class="justify-space-between">
                    <v-btn class="bg-info" @click="isActive.value = false">Close</v-btn>
                    <v-btn class="bg-red" @click="deleteConfirm">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>

<script setup>
import { ref } from 'vue';

const isDeleteItem = ref(false);

const props = defineProps(['type', 'source', 'value'])
const emit = defineEmits(['deleteItem']);

const type = ref(props.type);
const source = ref(props.source);
const value = ref(props.value);

const deleteConfirm = () => {
    emit('deleteItem', type.value, source.value, value.value );
    isDeleteItem.value = false;
}

</script>