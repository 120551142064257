<template>
    <v-container class="mt-10 pa-5 h-screen">
        <v-card class="pa-10 mx-auto" max-width="700px">
            <v-form>
                <h1 class="mb-10 text-center">User Login</h1>
                <v-text-field type="email" class="mb-5" v-model="userEmail" placeholder="2xxxxxxx@life.hkbu.edu.hk"
                    label="Email" variant="outlined" required></v-text-field>
                <v-text-field type="password" class="mb-2" v-model="userPassword" label="Password" variant="outlined"
                    required></v-text-field>

                <v-span class="float-right mb-6"><a @click="handleForgetPassword" href="/login/reset">Forgot
                        Password?</a></v-span>
                <v-btn @click="login" block class="mt-5 mb-2 pa-6 bg-light-blue-darken-4" text="Login"></v-btn>

                <v-dialog transition="dialog-top-transition" width="auto" v-model="isShowDialog">
                    <template v-slot:default="{ isActive }">
                        <v-card>
                            <v-toolbar color="success" title="Login Success"></v-toolbar>
                            <v-card-text>
                                <div class="text-h5 pa-10">Welcome {{ userEmail }} user.</div>
                            </v-card-text>
                            <v-card-actions class="justify-space-between">
                                <v-btn class="bg-info" @click="isActive.value = false">Close</v-btn>
                                <v-btn class="bg-info" @click="isActive.value = false" href="/service">Your
                                    Service</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>

                <v-dialog transition="dialog-top-transition" width="auto" v-model="isShowResendSuccessDialog">
                    <template v-slot:default="{ isActive }">
                        <v-card>
                            <v-toolbar color="success" title="Resend email verification Success"></v-toolbar>
                            <v-card-text>
                                <div class="text-h5 pa-10">The verification link is sent to your email. Please click the
                                    link to verify your account.</div>
                            </v-card-text>
                            <v-card-actions class="justify-space-between">
                                <v-btn class="bg-info" @click="isActive.value = false">Close</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>

                <v-dialog transition="dialog-top-transition" width="auto" v-model="isShowError">
                    <template v-slot:default="{ isActive }">
                        <v-card>
                            <v-toolbar class="bg-red" title="Login Failed"></v-toolbar>
                            <v-card-text>
                                <div class="text-h5 pa-10">{{ errMsg }}</div>
                            </v-card-text>
                            <v-card-actions class="">
                                <v-spacer></v-spacer>
                                <v-btn class="bg-red" @click="isActive.value = false">Close</v-btn>
                                <v-btn v-if="isShowResendEmail" class="bg-red" @click="resendEmailVerify">Resend email
                                    verification</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>

            </v-form>
        </v-card>
    </v-container>
</template>


<script setup>
import { ref } from 'vue';
import { getAuth, signInWithEmailAndPassword, sendEmailVerification, signOut, setPersistence, browserSessionPersistence } from 'firebase/auth';
import { useRouter } from 'vue-router';

const router = useRouter();
const userEmail = ref("");
const userPassword = ref("");
const isShowDialog = ref(false);

const isShowError = ref(false);
const isShowResendEmail = ref(false);
const isShowResendSuccessDialog = ref(false);
const errMsg = ref('');

const currUser = ref();

const login = () => {
    isShowResendEmail.value = false;
    // update the login to not persitence
    const auth = getAuth();
    setPersistence(auth, browserSessionPersistence).then(() => {
        signInWithEmailAndPassword(getAuth(), userEmail.value, userPassword.value)
            .then((data) => {

                // 2023/11/19 check the user is verified or not
                if (!data.user.emailVerified) {
                    // auto logout
                    currUser.value = getAuth().currentUser;
                    signOut(getAuth()).then(() => { });
                    isShowResendEmail.value = true;
                    errMsg.value = 'Please verify your account through email first and try to log in later.';
                    isShowError.value = true;
                    return;
                }

                // show log in success message
                isShowDialog.value = true;
            })
            .catch((err) => {
                switch (err.code) {
                    case "auth/invalid-email":
                        errMsg.value = "The email is invalid."
                        isShowError.value = true;
                        break;

                    case "auth/user-not-found":
                        errMsg.value = "The account is not exist, please create new account.";
                        isShowError.value = true;
                        break;

                    case "auth/wrong-password":
                        errMsg.value = "Incorrect passoword";
                        isShowError.value = true;
                        break;

                    case "auth/invalid-login-credentials":
                        errMsg.value = "Incorrect password";
                        isShowError.value = true;
                        break;

                    default:
                        errMsg.value = "Email or password was incorrect";
                        isShowError.value = true;
                        break;
                }
            });
    }).catch((err) => {
        errMsg.value = "Unexpected error occurs. Please try to login later.";
        isShowError.value = true;
    });

    // signInWithEmailAndPassword(getAuth(), userEmail.value, userPassword.value)
    //     .then((data) => {

    //         // 2023/11/19 check the user is verified or not
    //         if (!data.user.emailVerified) {
    //             // auto logout
    //             currUser.value = getAuth().currentUser;
    //             signOut(getAuth()).then(() => {});
    //             isShowResendEmail.value = true;
    //             errMsg.value = 'Please verify your account through email first and try to log in later.';
    //             isShowError.value = true;
    //             return;
    //         }

    //         // show log in success message
    //         isShowDialog.value = true;
    //     })
    //     .catch((err) => {
    //         switch (err.code) {
    //             case "auth/invalid-email": 
    //                 errMsg.value = "The email is invalid."
    //                 isShowError.value = true;
    //                 break;

    //             case "auth/user-not-found": 
    //                 errMsg.value = "The account is not exist, please create new account.";
    //                 isShowError.value = true;
    //                 break;

    //             case "auth/wrong-password": 
    //                 errMsg.value = "Incorrect passoword";
    //                 isShowError.value = true;
    //                 break;

    //             case "auth/invalid-login-credentials":
    //                 errMsg.value = "Incorrect password";
    //                 isShowError.value = true;
    //                 break;

    //             default: 
    //                 errMsg.value = "Email or password was incorrect";
    //                 isShowError.value = true;
    //                 break;
    //         }
    //     });
};

// 2023/11/19 resend the email verify 
const resendEmailVerify = () => {
    sendEmailVerification(currUser.value)
        .then(() => {
            isShowError.value = false;
            isShowResendSuccessDialog.value = true;
        }).catch((err) => {
        });
    currUser.value = null;
}

</script>